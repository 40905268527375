<script>
    import {Bar} from 'vue-chartjs'
    import {CustomTooltips} from '@coreui/coreui-plugin-chartjs-custom-tooltips'
    import {hexToRgba} from '@coreui/coreui/dist/js/coreui-utilities'

    export default {
        components: {
            hexToRgba,
            CustomTooltips
        },
        extends: Bar,
        mounted() {
            this.renderChart(
                {
                    labels: ['Cantidad'],
                    datasets: [
                        {
                            label: 'Restaurantes',
                            backgroundColor: hexToRgba('#00ff41', 70),
                            fill: true,
                            data: [7]
                        },
                        {
                            label: 'Delivery',
                            backgroundColor: hexToRgba('#ffec3c', 70),
                            fill: true,
                            data: [4]
                        },
                        {
                            label: 'Fiambrería y Abarrotes',
                            backgroundColor: hexToRgba('#ff0326', 70),
                            fill: true,
                            data: [2]
                        },
                        {
                            label: 'Capacitaciones BPM y Lobesia Botrana',
                            backgroundColor: hexToRgba('#09c0ff', 70),
                            fill: true,
                            data: [5]
                        },
                        {
                            label: 'Personas Capacitadas',
                            backgroundColor: hexToRgba('#b251ff', 70),
                            fill: true,
                            data: [34]
                        },
                        {
                            label: 'Gestión de Resolución Sanitaria Foods Trucks',
                            backgroundColor: hexToRgba('#ff5d84', 70),
                            fill: true,
                            data: [4]
                        }

                    ]
                },
                {
                    responsive: true,
                    scales: {
                        yAxes: [{
                            stacked: false,
                            ticks: {
                                precision: 0,
                                beginAtZero: true,
                                suggestedMax: 50
                            }
                        }]
                    },
                    maintainAspectRatio: true,
                    tooltips: {
                        enabled: false,
                        custom: CustomTooltips,
                        intersect: false,
                        mode: 'index',
                        position: 'nearest',
                        callbacks: {
                            labelColor: function (tooltipItem, chart) {
                                return {backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor}
                            }
                        }
                    }
                }
            )
        }
    }
</script>
