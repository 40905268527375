<script>
    import {Pie} from 'vue-chartjs'

    export default {
        extends: Pie,
        mounted() {
            this.renderChart({
                labels: ['Pacto colectivo', 'Sin Convención colectiva'],
                datasets: [
                    {
                        label: '2018',
                        backgroundColor: [
                            '#00a1b8',
                            '#9de43e'
                        ],
                        data: [240, 269]
                    }
                ]
            }, {responsive: true, maintainAspectRatio: true})
        }
    }
</script>
