<script>
    import {PolarArea} from 'vue-chartjs'
    import {CustomTooltips} from '@coreui/coreui-plugin-chartjs-custom-tooltips'
    import {hexToRgba} from '@coreui/coreui/dist/js/coreui-utilities'

    export default {
        components: {
            hexToRgba,
            CustomTooltips
        },
        extends: PolarArea,
        mounted() {
            this.renderChart(
                {
                    labels: ['Papa', 'Tomate', 'Cebolla', 'Lechuga', 'Zanahoría'],
                    datasets: [
                        {
                            label: 'Hortalizas (ton)',
                            backgroundColor: [hexToRgba('#ff0002', 70), hexToRgba('#00ff1c', 70), hexToRgba('#002fff', 70), hexToRgba('#00fcff', 70), hexToRgba('#ff5900', 70)],
                            fill: true,
                            data: [228125, 186333, 168573, 117130, 74455]
                        }
                    ]
                },
                {
                    responsive: true,
                    maintainAspectRatio: true,
                    tooltips: {
                        enabled: false,
                        custom: CustomTooltips,
                        intersect: true,
                        mode: 'index',
                        position: 'nearest',
                        callbacks: {
                            labelColor: function (tooltipItem, chart) {
                                return {backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor}
                            }
                        }
                    }
                }
            )
        }
    }
</script>
