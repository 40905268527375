<script>
    import {Pie} from 'vue-chartjs'

    export default {
        extends: Pie,
        mounted() {
            this.renderChart({
                labels: ['Evaluados', 'No Evaluados'],
                datasets: [
                    {
                        label: '2018',
                        backgroundColor: [
                            '#ffec3c',
                            '#ff9400'
                        ],
                        data: [83, 17]
                    }
                ]
            }, {responsive: true, maintainAspectRatio: true})
        }
    }
</script>
