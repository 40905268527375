<script>
    import {Doughnut} from 'vue-chartjs'

    export default {
        extends: Doughnut,
        mounted() {
            this.renderChart({
                labels: ['Directos', 'Indirectos'],
                datasets: [
                    {
                        backgroundColor: [
                            '#41B883',
                            '#E46651'
                        ],
                        data: [508, 5000]
                    }
                ]
            }, {responsive: true, maintainAspectRatio: true})
        }
    }
</script>
