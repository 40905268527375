<script>
    import {Pie} from 'vue-chartjs'

    export default {
        extends: Pie,
        mounted() {
            this.renderChart({
                labels: ['Indefinido', 'Fijo'],
                datasets: [
                    {
                        label: '2018',
                        backgroundColor: [
                            '#40b800',
                            '#e4000f'
                        ],
                        data: [451, 57]
                    }
                ]
            }, {responsive: true, maintainAspectRatio: true})
        }
    }
</script>
